import { ContextMenuTriggerProps } from "@radix-ui/react-context-menu";
import { Input } from "./ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
  ContextMenuPortal
} from "./ui/context-menu";
import axios from "axios";
import React, { ChangeEvent, Ref, useEffect, useState } from "react";
import {Matter, Contact} from "src/lib/conflix";
import SheetCell from "./sheetCell";

const apiUrl = process.env.REACT_APP_API_URL;

function MatterRow(props: {
  matter: Matter;
  idToken: string;
  org: string;
  onLoading: (isLoading: boolean) => void;
}) {
  const [editedField, setEditedField] = useState<string>("");
  const [refNumberValue, setRefNumberValue] = useState<string>(
    props.matter.referenceNumber
  );
  const [nameValue, setNameValue] = useState<string>(props.matter.name);

  const shortCreateDate = props.matter.createdAt.split("T")[0];

  

  const onDelete = async () => {
    props.onLoading(true);
    
    const response = await axios.delete(
      `${apiUrl}matters/${props.matter.matterId}`,
      {
        headers: {
          Authorization: props.idToken,
          "x-orgslug": props.org,
          "Content-Type": "application/json",
        },
      }
    );

    props.onLoading(false);
  }

  return (
    <ContextMenu>
      <ContextMenuTrigger asChild>
        <TableRow className="hover:bg-gray-50">
          <SheetCell field="referenceNumber" matter={props.matter} idToken={props.idToken} org={props.org} onLoading={props.onLoading}/>
          <SheetCell field="name" matter={props.matter} idToken={props.idToken} org={props.org} onLoading={props.onLoading}/>
          <SheetCell field="createdAt" matter={props.matter} idToken={props.idToken} org={props.org} onLoading={props.onLoading}/>
        </TableRow>
      </ContextMenuTrigger>
      <ContextMenuContent>
        <ContextMenuItem onClick={onDelete} inset>Delete</ContextMenuItem>
      </ContextMenuContent>
    </ContextMenu>
  );
}

export default MatterRow;
