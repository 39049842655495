import MatterRow from "./matter";
import { Input } from "./ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import axios from "axios";
import React, { SetStateAction, useEffect, useState } from "react";

const apiUrl = process.env.REACT_APP_API_URL;

function NewMatter(props: { idToken: string, org: string, nextRefNum: string, onLoading: (isLoading: boolean) => void  }) {
    const [loading, setLoading] = useState<boolean>(false);
    const [refNumber, setRefNumber] = useState<string>(props.nextRefNum);

    const handleCreate = async (e: React.FocusEvent<HTMLInputElement> ) => {
        setLoading(true);
        props.onLoading(true);
    
        try {
          const response = await axios.post(
            `${apiUrl}matters`,
            { name: e.currentTarget.value, referenceNumber: refNumber },
            {
              headers: {
                "Content-Type": "application/json",
                "Authorization": props.idToken,
                "x-orgslug": props.org
              },
            }
          );
        } catch (error) {
          console.error("Error creating matter:", error);
        }

        setLoading(false);
        props.onLoading(false);
    }

    const handleEnter = (e: React.KeyboardEvent<HTMLInputElement> ) => {
        if (e.key === "Enter") {
          e.currentTarget.blur();
        }
    }

    return (
        <TableRow className="hover:bg-gray-50">
            <TableCell className="font-medium"><Input value={props.nextRefNum} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRefNumber(e.currentTarget.value)} /></TableCell>
            <TableCell>
                {loading ? <div>Loading...</div> : <Input placeholder="Title" onBlur={handleCreate} onKeyDown={handleEnter} /> }
            </TableCell>
        </TableRow>
    );
}

export default NewMatter;