import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
  } from "./ui/card";
import { Label } from "./ui/label";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import React, { ChangeEvent, useEffect, useState } from "react";
import axios from "axios";
import { json } from "stream/consumers";

const apiUrl = process.env.REACT_APP_API_URL;

function LoginCard( props : { onLogin : (idToken : string) => void }) {

    const [newPassRequired, setNewPassRequired] = useState<boolean>(false);
    const [authenticating, setAuthenticating] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [emailValue, setEmailValue] = useState<string>("");
    const [passwordValue, setPasswordValue] = useState<string>("");
    const [newPassValue, setNewPassValue] = useState<string>("");
    const [sessionKey, setSessionKey] = useState<string>("");


    useEffect(() => {
        if (!authenticating) {
            return;
        }
        if (!newPassRequired) {
            const input = { username: emailValue, password: passwordValue }

            const authenticate = async () => {
                try {
                    const response = await axios.post(`${apiUrl}authenticate`,
                        JSON.stringify(input)
                    )

                    if (response.status === 200 && response.data.challengeName == "NEW_PASSWORD_REQUIRED") {
                        setSessionKey(response.data.session);
                        setErrorMessage("");
                        setNewPassRequired(true);
                    } else if (response.status === 200) {
                        console.log(response.data.tokens.idToken);
                        setErrorMessage("");
                        props.onLogin(response.data.tokens.idToken);
                    } else if (response.data.message) {
                        setErrorMessage(`${response.statusText}: ${response.data.message}`);
                    }
                    
                } catch (error) {
                    setErrorMessage(error as string);
                    console.error("Error authenticating:", error);
                }
            };

            authenticate();
            setAuthenticating(false);
        } else {
            const input = { username: emailValue, password: passwordValue, new_password: newPassValue, session: sessionKey }

            const authenticate = async () => {
                try {
                    const response = await axios.post(`${apiUrl}authenticate`,
                        JSON.stringify(input)
                    )
                    if (response.status === 200 && typeof response.data.tokens.idToken !== undefined) {
                        setErrorMessage("");
                        props.onLogin(response.data.tokens.idToken);
                    } else if (response.data.message) {
                        setErrorMessage(`${response.statusText}: ${response.data.message}`);
                    }
                    
                } catch (error) {
                    setErrorMessage(error as string);
                    console.error("Error authenticating:", error);
                }
            };

            authenticate();
            setNewPassRequired(false);
            setAuthenticating(false);
        }
    }, [authenticating])

    return <div className="static min-h-screen w-full flex items-center justify-center transform -translate-y-1/4">
        <Card className="w-full max-w-2xl">
        <CardHeader>
            <CardTitle>Log In</CardTitle>
            <CardDescription className="text-destructive-foreground">{errorMessage === "" ? null : errorMessage}</CardDescription>
        </CardHeader>
        <CardContent>
            <div>
                <Label htmlFor="email">Email</Label>
                <Input id="email" value={emailValue} onChange={(e: ChangeEvent<HTMLInputElement>) => setEmailValue(e.currentTarget.value) } placeholder="yourname@email.com"/>
            </div>
            <div>
                <Label htmlFor="password">Password</Label>
                <Input id="password" type="password" value={passwordValue} onChange={(e: ChangeEvent<HTMLInputElement>) => setPasswordValue(e.currentTarget.value)}/>
            </div>
            {newPassRequired ? <div>
                <Label htmlFor="newPassword">New Password</Label>
                <Input id="newPassword" type="password" value={newPassValue} onChange={(e: ChangeEvent<HTMLInputElement>) => setNewPassValue(e.currentTarget.value)}/>
            </div> : null }
        </CardContent>
        <CardFooter className="flex justify-end">
            <Button onClick={() => setAuthenticating(true)}>{newPassRequired ? "Log In" : "Next"}</Button>
        </CardFooter>
    </Card>
    </div> 
}

export default LoginCard;
