import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './globals.css';
import './output.css';
import Spreadsheet from "./components/spreadsheet"
import LoginCard from './components/login';
import { Button } from "./components/ui/button";
import axios, { Axios } from 'axios';
import { log } from 'console';

const org = process.env.REACT_APP_ORG;

function App() {
  const [loggedIn, setLoggedIn] = useState<boolean>(() => {
    const stored = sessionStorage.getItem("isAuthenticated");
    return stored ? JSON.parse(stored) : false;
  });

  const [idToken, setIdToken] = useState<string>(() => {
    const stored = sessionStorage.getItem("idToken");
    return stored ? stored : "";
  });

  useEffect(() => {
    sessionStorage.setItem("isAuthenticated", JSON.stringify(loggedIn));
    sessionStorage.setItem("idToken", JSON.stringify(idToken));
  }, [loggedIn]);

  useEffect(() => {
    sessionStorage.setItem("idToken", idToken)
  }, [idToken]);

  console.log(loggedIn);

  const renderContent = () => {
    if (!loggedIn) {
      return <LoginCard onLogin={ (id : string) => { setLoggedIn(loggedIn => true); setIdToken(idToken => id); } }/>
    } else {
      console.log(idToken);
      return <div>
        <Button onClick={ () => { setLoggedIn(loggedIn => false); setIdToken(""); }} >Log Out</Button>
        <Spreadsheet idToken={idToken} org={org as string} />
      </div>
    }
  }

  return (
    <div className="min-h-screen bg-gray-50 p-4">

      { renderContent() }
      
    </div>
  );
}

export default App;
