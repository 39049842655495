import React, { ChangeEvent, Ref, useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "./ui/table";
import { Input } from "./ui/input";
import axios from "axios";
import { table } from "console";
import { Contact, Matter } from "src/lib/conflix";

const apiUrl = process.env.REACT_APP_API_URL;

function SheetCell(
    props: {
        matter?: Matter;
        contact?: Contact; 
        field: string;
        idToken: string;
        org: string;
        onLoading: (isLoading: boolean) => void;
    }
) {
    function GetInitValue() : string {
        if (props.matter) {
            const prop = Object.entries(props.matter).find((property) => { return property[0] === props.field});
            if (!prop) return "";
            return prop[1]
        } else if (props.contact) {
            const prop = Object.entries(props.contact).find((property) => { return property[0] === props.field});
            if (!prop) return "";
            return prop[1]
        } else {
            console.error("Spreadsheet cell is missing data: did you forget to add a matter or contact parameter?")
            return "";
        }
    }

    const [editing, setEditing] = useState<boolean>(false);
    const [value, setValue] = useState<string>(GetInitValue());

    const onEditField = async (e: React.FocusEvent<HTMLInputElement>) => {
        props.onLoading(true);

        if (props.matter) {
            type Data = {
                [key: string]: string | undefined
            }

            let data : Data  = {
                name: props.field === "name" ? value : props.matter.name,
                referenceNumber: props.field === "referenceNumber" ? value : props.matter.referenceNumber,
            }

            if (props.field !== "name" && props.field !== "referenceNumber") {
                data[props.field] = value;
            }
        
            const response = await axios.put(
                `${apiUrl}matters/${props.matter.matterId}`,
                data,
                {
                headers: {
                    Authorization: props.idToken,
                    "x-orgslug": props.org,
                    "Content-Type": "application/json",
                },
                }
            );
        }
    
        setEditing(false);
        props.onLoading(false);
    };

    const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
          e.currentTarget.blur();
        }
    };

    const onSelect = (e: React.MouseEvent<HTMLTableCellElement>) => {
        setEditing(true);
    };
    

    return (
        <TableCell
            className="font-medium"
            id="referenceNumber"
            onDoubleClick={onSelect}
        >
            {editing ? (
                <Input
                value={value}
                onBlur={onEditField}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setValue(e.currentTarget.value)
                }
                onKeyDown={handleEnter}
                autoFocus
                />
            ) : (
                value
            )}
        </TableCell>
    );
}

export default SheetCell;