import MatterRow from "./matter";
import NewMatter from "./newMatter";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./ui/card";
import { Input } from "./ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";

import axios from "axios";
import { title } from "process";
import React, { useEffect, useState } from "react";
import { Contact, Matter } from "src/lib/conflix";

const apiUrl = process.env.REACT_APP_API_URL;

function Spreadsheet(props: { idToken: string, org: string }) {
  const [matters, setMatter] = useState<Matter[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchMatter = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}matters`, 
          { 
            headers: {
            "Authorization": props.idToken,
            "x-orgslug": props.org
            }
          }
        );
        setMatter(response.data);
      } catch (error) {
        console.error("Error fetching matter:", error);
      }
    };

    fetchMatter();
  }, [loading]);

  const createRefNumber = () : string => {
    let highestNumber = 0;
    const currentYear = new Date().getFullYear().toString();
    matters?.forEach(matter => {
      const splitNumber = matter.referenceNumber?.split("-");
      if (splitNumber && splitNumber[0] === currentYear && Number(splitNumber[1]) > highestNumber) {
        highestNumber = Number(splitNumber[1]);
      }
    });

    const newRefNumber = `00${highestNumber + 1}`.slice(Math.min(highestNumber.toString().length - 1, 2));
    return `${currentYear}-${newRefNumber}`;
  }

  const onLoading = (isLoading: boolean) => {
    setLoading(isLoading);
  }

  if (!matters) {
    return <div>Loading...</div>;
  }

  if (!matters.length) return <div>No data available</div>;

  return (
    <Card className="max-w-4xl mx-auto my-8">
      <CardHeader>
        <CardTitle>Matter Spreadsheet</CardTitle>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-24">Number</TableHead>
              <TableHead>Title</TableHead>
              <TableHead>Created At</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {matters.map((matter) => (
                <MatterRow key={matter.matterId} matter={matter} idToken={props.idToken} org={props.org} onLoading={onLoading}/>
            ))}
            <NewMatter idToken={props.idToken} org={props.org} nextRefNum={createRefNumber()} onLoading={onLoading} />
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

export default Spreadsheet;
